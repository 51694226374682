<template>
  <div style="width:100%">
    <div v-for="(item, index) in item_registros"  :key="index">
          <listadoCorreo  v-if="item.subestado_id === 8" :colores="colores" :item="item" :session="session" @view_correo="ver_correo"></listadoCorreo>
          <listadoMicrositio  v-else-if="item.subestado_id === 7" :colores="colores" :item="item" :session="session" @view_micrositio="ver_micrositio"></listadoMicrositio>
          <listadoCrm  v-else :colores="colores" :item="item" :session="session" @view_crm="ver_crm"></listadoCrm>
    </div>
  <lecturaDialogo :dialogo="dialogLectura" :session="session" :gestion_id="gestion_id" :token="token" :items="items" :colores="colores" @closed_lectura="cerrar_lectura"></lecturaDialogo>
  <micrositioDialogo :dialogo="dialogMicrositio" :session="session" :gestion_id="gestion_id" :token="tokenSitio" :items="items" :colores="colores" @closed_micrositio="cerrar_micrositio"></micrositioDialogo>
  <correoDialogo :dialogo="dialogCorreo" :session="session" :gestion_id="gestion_id" :token="tokenCorreo" :items="items" :colores="colores" @closed_correo="cerrar_correo"></correoDialogo>
  </div>
</template>

<script>
// import listadoMicrositio from '@/components/crm/listados_micrositioComponent.vue'
// import listadoCorreo from '@/components/crm/listados_correosComponent.vue'
// import listadoCrm from '@/components/crm/listados_crmComponent.vue'
// import lecturaDialogo from '@/components/crm/lectura_dialogoComponent.vue'
// import micrositioDialogo from '@/components/micrositio/dialogoComponent.vue'
// import correoDialogo from '@/components/correos/dialogoComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'bandeja_pqrsComponent',
  components: {
    lecturaDialogo: () => import('@/components/crm/lectura_dialogoComponent.vue'),
    micrositioDialogo: () => import('@/components/micrositio/dialogoComponent.vue'),
    correoDialogo: () => import('@/components/correos/dialogoComponent.vue'),
    listadoMicrositio: () => import('@/components/crm/listados_micrositioComponent.vue'),
    listadoCrm: () => import('@/components/crm/listados_crmComponent.vue'),
    listadoCorreo: () => import('@/components/crm/listados_correosComponent.vue')
  },
  mixins: [recFunciones],
  props: ['colores', 'items', 'item_registros', 'session'],
  data: () => ({
    dialogLectura: false,
    token: 0,
    gestion_id: 0,
    dialogMicrositio: false,
    tokenSitio: 0,
    dialogCorreo: false,
    tokenCorreo: 0
  }),
  methods: {
    nuevo_pqr () {
      this.$emit('new_inbound')
    },
    abrirColores () {

    },
    ver_crm (pItem) {
      this.gestion_id = pItem.id
      this.token = this.token + 1
      this.dialogLectura = true
    },
    ver_micrositio (pItem) {
      this.gestion_id = pItem.id
      this.tokenSitio = this.tokenSitio + 1
      this.dialogMicrositio = true
    },
    ver_correo (pItem) {
      this.dialogCorreo = true
      this.gestion_id = pItem.id
      this.tokenCorreo = this.tokenCorreo + 1
    },
    cerrar_lectura () {
      this.dialogLectura = false
    },
    cerrar_micrositio () {
      this.dialogMicrositio = false
    },
    cerrar_correo () {
      this.dialogCorreo = false
    }

  }
}
</script>
